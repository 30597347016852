import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";
import styles from "../style/pages/index.module.scss";

const Index = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const featured = data.allMarkdownRemark.edges[0].node.frontmatter;
  return (
    <Layout title={siteTitle}>
      <SEO title="Home" />
      <div className={styles.homePage}>
        {featured && (
          <header className={styles.header}>
            <div>
              <h1 className={styles.siteTitle}>{featured.title}</h1>
              <span className={styles.subtitle}>
                {
                  "Pick up your copy of the latest book by Pastor, J. Scott George."
                }
              </span>
            </div>
            <div className={styles.actions}>
              <a href={`${featured.purchase_url}`} target="_blank">
                <button>buy now</button>
              </a>
            </div>
          </header>
        )}
      </div>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/book/" } }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            purchase_url
          }
        }
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => (
      <Index location={props.location} props data={data} {...props} />
    )}
  />
);
